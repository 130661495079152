import { createRoute } from '../../sketch-platform/ui/routing';

const serviceName = 'n'; // configファイルも変更する

const routes = {
  home: createRoute({ path: '/' }), // 総合TOP(TRENTA UI)
  homeEachTrenta: createRoute({ path: '/-/:companyCode' }), // 各局TOP(TRENTA UI)
  articleDetailJson: createRoute({ path: `/nnn/:id` }), // 記事詳細（list.json版）
  companyHome: createRoute({ path: `/${serviceName}/:companyCode` }), // 各局TOP(各社UI)
  category: createRoute({ path: '/category/:categoryCode' }), // カテゴリーTOP
  articles: createRoute({ path: '/articles/:id' }), // 記事詳細（カテゴリなし）
  articleDetail: createRoute({ path: '/category/:categoryCode/:id' }), // 記事詳細(TRENTA UI)
  articlesImages: createRoute({ path: '/articles/:id/image' }), // 画像一覧
  articleDetailImages: createRoute({ path: '/category/:categoryCode/:id/image' }), // 画像一覧
  articleDetailImagesTrenta: createRoute({ path: `/category/:categoryCode/:id/image` }), // 画像一覧(TRENTA UI)
  articleDetailImagesEachCompany: createRoute({ path: `/${serviceName}/:companyCode/category/:categoryCode/:id/image` }), // 画像一覧(各社UI)
  newTrenta: createRoute({ path: '/new/:categoryCode' }), // 最新のニュース一覧(TRENTA UI)
  newEachTrenta: createRoute({ path: '/-/:companyCode/new/:categoryCode' }), // 最新の各社のニュース一覧(TRENTA UI)
  newEachCompany: createRoute({ path: `/${serviceName}/:companyCode/new/:categoryCode` }), // 最新の各社のニュース一覧(各社 UI)
  articlesEachCompany: createRoute({ path: `/articles/:companyCode/:id` }), // 記事詳細(各社UI)
  articleDetailEachCompany: createRoute({ path: `/${serviceName}/:companyCode/category/:categoryCode/:id` }), // 記事詳細(各社UI)
  featureTrenta: createRoute({ path: '/feature' }), // 特集TOP(TRENTA UI)
  featureDetailTrenta: createRoute({ path: '/feature/:tagName' }), // 特集記事一覧(TRENTA UI)
  featureEachCompany: createRoute({ path: `/${serviceName}/:companyCode/feature` }), // 特集TOP(各社UI)
  featureDetailEachCompany: createRoute({ path: `/${serviceName}/:companyCode/feature/:tagName` }), // 特集記事一覧(各社UI)
  local: createRoute({ path: `/${serviceName}` }), // 地方局一覧(日本地図)
  liveTrenta: createRoute({ path: '/live' }), // ライブページ(TRENTA UI)
  liveEachCompany: createRoute({ path: `/${serviceName}/:companyCode/live` }), // ライブページ(各局UI)
  rankingTrenta: createRoute({ path: '/ranking/:categoryCode' }), // ランキング(TRENTA UI)
  rankingEachCompany: createRoute({ path: `/${serviceName}/:companyCode/ranking/:categoryCode` }), // ランキング(各局UI)
  tag: createRoute({ path: '/tag/:tagName' }), // ハッシュタグ記事一覧
  author: createRoute({ path: '/author/:authorName' }), // Author記事一覧(TRENTA UI)
  authorEachCompany: createRoute({ path: `/${serviceName}/:companyCode/author/:authorName` }), // Author記事一覧(各局UI)
  info: createRoute({ path: '/info' }), // お知らせ一覧(TRENTA UI)
  infoDetail: createRoute({ path: '/info/:id' }), // お知らせ詳細(TRENTA UI)
  infoEachCompany: createRoute({ path: `/${serviceName}/:companyCode/info` }), // お知らせ一覧(各局UI)
  infoDetailEachCompany: createRoute({ path: `/${serviceName}/:companyCode/info/:id` }), // お知らせ詳細(各局UI)
  search: createRoute({ path: '/search' }), // 検索結果一覧
  sns: createRoute({ path: '/sns' }), // SNS一覧
  error: createRoute({ path: '/error' }), // 汎用エラーページ
  semiStaticPage: createRoute({ path: '/pages/:jsonFileName' }), // 半静的ページ(pages/*.jsonから画面HTML表示)
};

export default routes;
