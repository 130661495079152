import _indexOf from 'lodash/indexOf';
/**
 * 著者の表示用の名前を出力する
 * TYPELINEの著者名に@の識別子が含まれている場合は、その前方を返却する
 * @が含まれていない場合は、登録された名前をそのまま返す
 * @param {string} _name TYPELINEに登録された著者名
 * @return {string} 整形された名前
 */
export default function getDisplayName(_name = '') {
  const atMarkIndex = _indexOf(_name, '@');
  if (0 < atMarkIndex) {
    return _name.substring(0, atMarkIndex);
  } else {
    return _name;
  }
}
