import _get from 'lodash/get';

/**
 * 広告タグのdivタグIDに紐付ける画面名を変換します
 * 各局UIかそうでないかに応じてnews_nnn_を付帯するかも決定します
 */
export default (context) => {
  const uiView = _get(context, 'routeHandler.uiView', '');
  const isEachCompany = _get(context, 'routeHandler.isEachCompany', false);
  if (!uiView) return '';

  switch (uiView) {
    // ヘッドライン(TRENTA TOP, 各社TOP)
    case 'home':
    case 'companyHome':
      return isEachCompany ? 'news_nnn_top' : 'news_top';
    // 新着一覧(TERNTA UI, 各社Ui)
    case 'new':
      return isEachCompany ? 'news_nnn_new' : 'news_new';
    // 記事詳細画面(TRENTA UI, 各社UI)
    case 'articleDetail':
      return isEachCompany ? 'news_nnn_article' : 'news_article';
    // 記事詳細画像ページ
    case 'ArticlesContentImages':
    case 'ArticleDetailContentImages':
    case 'ArticleDetailContentImagesEachCompany':
      return isEachCompany ? 'news_nnn_image' : 'news_image';
    // 特集一覧・特集記事一覧
    case 'special':
    case 'specialdetail':
      return isEachCompany ? 'news_nnn_feature' : 'news_feature';
    // ランキング
    case 'ranking':
      return isEachCompany ? 'news_nnn_ranking' : 'news_ranking';
    // 検索
    case'search':
      return isEachCompany ? 'news_nnn_search' : 'news_search';
    // 地域(日本地図)
    case 'local':
      return 'news_area';
    // タグ記事一覧
    case 'tag':
      return isEachCompany ? 'news_nnn_tag' : 'news_tag';
    // ライブ
    case 'live':
      return isEachCompany ? 'news_nnn_live' : 'news_live';
    // 著者記事一覧画面
    case 'Author':
    case 'AuthorEachCompany':
      return isEachCompany ? 'news_nnn_author' : 'news_author';
    // 上記に該当しないもの
    default:
      return 'news_other';
  }
}
