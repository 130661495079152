'use strict';

import React from 'react';

export const newlineRegex = /(\r\n|\r|\n|\\n|\<br\>)/g;

export default function nl2br(str) {
  if (typeof str === 'number') {
    return str;
  } else if (typeof str !== 'string') {
    return null;
  }

  return str.split(newlineRegex).map(function(line, index) {
    if (line.match(newlineRegex)) {
      return React.createElement('br', { key: index });
    }
    return line;
  });
};

