import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

/**
 * 新着一覧ページの閾値取得
 */
const getNewContentPageNofollowThreshold = (json, uiType, pageType, categoryCode = '') => {
  let threshold = _get(json, ['nofollowThreshold', uiType, pageType, categoryCode], null);
  if (threshold === null) {
    // 該当するカテゴリーがない場合はデフォルト参照
    threshold = _get(json, ['nofollowThreshold', uiType, pageType, 'default'], null);
  }
  return threshold;
}

// 以下のついてはTODO対応

/**
 * タグ記事一覧画面
 */
const getTagContentPageNofollowThreshold = (json, uiType, pageType, categoryCode) => {
  return null;
}

/**
 * 特集一覧
 */
const getFeaturePageNofollowThreshold = (json, uiType, pageType, categoryCode) => {
  return null;
}

/**
 * 特集記事一覧
 */
const getFeatureArticlesPageNofollowThreshold = (json, uiType, pageType, categoryCode) => {
  return null;
}

/**
 * 著者記事一覧
 */
const getAuthorPageNofollowThreshold = (json, uiType, pageType, categoryCode) => {
  return null;
}

/**
 * seo.jsonからnofollowに関する閾値を取得する。
 * @param {object} json seo.jsonの中身
 * @param {string} pageType 対象の画面キー
 * @param {string} uiType 全局(all): trenta_ui, TRENTA各局UI: each_trenta_ui, 各局UI: each_company_ui
 * @param {string} categoryCode カテゴリコード。新着記事画面の場合は必須
 * @return {number | null} 閾値
 */
export default (json = {}, uiType = '', pageType = '', categoryCode = '') => {
  if (_isEmpty(json) || !uiType || !pageType) return null;

  switch (pageType) {
    case 'new':
      return getNewContentPageNofollowThreshold(json, uiType, pageType, categoryCode);
    case 'tag':
      return getTagContentPageNofollowThreshold(json, uiType, pageType, categoryCode);
    case 'feature':
      return getFeaturePageNofollowThreshold(json, uiType, pageType, categoryCode);
    case 'featureArticles':
      return getFeatureArticlesPageNofollowThreshold(json, uiType, pageType, categoryCode);
    case 'author':
      return getAuthorPageNofollowThreshold(json, uiType, pageType, categoryCode);
    default:
      return null;
  }
}
