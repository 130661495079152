import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import Share from './Share';
import dayjs from 'dayjs';
import Player from './Player';
import BackgroundImage from '../../../common/components/BackgroundImage';
import str2a from '../../../../common/react-str2a';
import nl2br from '../../../../common/react-nl2br';
import classnames from 'classnames';
import getImage from '../../../../common/getImage';
import getResizedImage from '../../../../common/getResizedImage';
import * as ERROR from '../../../../constants/error';
import lineClamp from '../../../../common/lineClamp';
import routes from '../../../common/routes';
import MainViewLink from '../../../common/components/MainViewLink';
import getBroadcasterData from '../../../../common/getBroadcasterData';

class InformationContentDetail extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    isSpaError: PropTypes.func,
    getModelData: PropTypes.func,
    topText: PropTypes.string
  };

  static getPaths = function (models, options, props) {
    const rootPath = this.getRootPath(models, options, props);

    return [rootPath];
  };

  static getRootPath = function (models, options, props) {
    return ['notification', props.id];
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  static afterPrefetch = function (models, options, props) {
    const self = this;
    return (prefetchResult) => {
      const rootPath = self.getRootPath(models, null, props);
      const item = _.get(prefetchResult, ['json'].concat(rootPath));
      if (!item) return { error: ERROR.NOTFOUND };
      return null;
    };
  };

  constructor(props, context) {
    super(props, context);

    const rootPath = this.constructor.getRootPath(context.models, null, props);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.item = this.model.getSync(rootPath);

    this.state = {};
    if (this.item) {
      // informationの場合、PC/SPサイズ同一
      const articleImage = getImage({ itemData: this.item, information: true }, this.context);
      if (articleImage) this.props.setPreloadImages(articleImage);
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let spBlock;
    let pcBlock;
    const browserInfo = this.context.getModelData('browserInfo');

    if (!this.item) {
      if (_.get(this.context, 'isSpaError')) {
        this.context.isSpaError();
      }
      throw new NotFoundError();
    }

    const to = !_.get(this.context.routeHandler, 'isEachCompany') ? routes.info : routes.infoEachCompany;
    const params = {companyCode :_.get(this.context.routeHandler, 'params.companyCode')};

    spBlock = (
      <React.Fragment>
        <article className="informationDetail">
          <div className="informationDetail-content">
            <div className="informationDetail-content-header">
              <div className="informationDetail-content-header-categories">
                <div className="informationDetail-content-header-categories-badge">
                  <MainViewLink to={to} params={params} className="informationDetail-content-header-categories-badge-link">
                    お知らせ
                  </MainViewLink>
                </div>
              </div>
              <h1 className="informationDetail-content-header-title">
                {browserInfo.isIE ? lineClamp(_.get(this.item, 'title'), 56) : _.get(this.item, 'title')}
              </h1>
              <div className="informationDetail-content-header-categories-meta-bottom">
                <Share title={_.get(this.item, 'title', '')} />
                <div className="informationDetail-content-header-categories-meta-bottom-date">
                  {dayjs(_.get(this.item, 'publish_start_date')).format('YYYY年M月D日 H:mm')}
                </div>
              </div>
            </div>
            <div className={classnames('player-block', { 'articles-thumbnail-img': !this.item.media })}>
              {this.item.media ? (
                <Player />
              ) : (
                <BackgroundImage
                  className="artwork articles-thumbnail"
                  url={getImage({ itemData: this.item, information: true }, this.context)}
                  key={'information'}
                  altText={_.get(this.item, 'title')}
                />
              )}
            </div>
            <p className="player-text">{str2a(nl2br(_.get(this.item, 'content', '')))}</p>
          </div>
        </article>
      </React.Fragment>
    );

    pcBlock = (
        <React.Fragment>
          <article className="player-contents mt-24">
            <div className="tag-contents">
              <MainViewLink to={to} params={params} className="articleDetail-content-header-categories-badge fw-700">お知らせ</MainViewLink>
            </div>
            <h1 className="articleDetail-content-header-title">{browserInfo.isIE ? lineClamp(_.get(this.item, 'title'), 56) : _.get(this.item, 'title')}</h1>
            <div className="tag-contents info-date-wrapper">
              <Share title={_.get(this.item, 'title', '')} />
              <div className="date gray-color">
                {dayjs(_.get(this.item, 'publish_start_date')).format('YYYY年M月D日 H:mm')}
              </div>
            </div>
            <div className={classnames('player-block',{'articles-thumbnail-img': !this.item.media})}>
              {this.item.media ?
                  <Player />
                  : <BackgroundImage className="artwork articles-thumbnail" url={getImage({itemData: this.item, information: true}, this.context)} key={"information"} altText={_.get(this.item, 'title')} />
              }
            </div>
            <p className="player-text">{str2a(nl2br(_.get(this.item, 'content', '')))}</p>
        </article>
      </React.Fragment>
    );

    const ogImageWidth = 630;
    const image = getResizedImage({ itemData: this.item, information: true }, this.context, ogImageWidth);

    const title = `${_.get(this.item, 'title')}｜お知らせ`;

    const canonical = `/info/${_.get(this.item, 'id')}`;

    const topText = _.get(this.context, 'topText');
    const company = _.get(this.props.routeHandler, 'params.companyCode', 'n24');
    const bloadcaster = getBroadcasterData(company)
    const titleTag = `${_.get(this.item, 'title')}｜お知らせ｜${company === 'n24' ? topText : bloadcaster.nnn}`
    const firstParagraph = this.item.content.split(/\n\n/)[0];

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle(titleTag) });
            metas.push({ property: 'og:type', content: 'article' });
            if (_.get(this.item, 'content')) {
              metas.push({ name: 'description', content: firstParagraph });
              metas.push({ property: 'og:description', content: firstParagraph });
            }
            metas.push({ property: 'ntvnews-recommendable-article', content: 'false' });
            metas.push({ property: 'og:image', content: image });

            const linkTags = this.props.getPreloadImages();

            return <Helmet title={titleTag} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        {this.context.spMode ? spBlock : pcBlock}
        <Analytics pageTitle={titleTag} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(InformationContentDetail);
root.getPaths = InformationContentDetail.getPaths;
root.getRootPath = InformationContentDetail.getRootPath;
root.getPrefetchPaths = InformationContentDetail.getPrefetchPaths;
root.afterPrefetch = InformationContentDetail.afterPrefetch;
export default root;
