/**
 * Author記事一覧(タグ記事一覧ベース)
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import TitleCard from './TitleCard';
import Share from './Share';
import PagerBlock from './PagerBlock';
import nl2br, { newlineRegex } from "../../../../common/react-nl2br";
import str2a, { urlRegex, boldRegex } from "../../../../common/react-str2a";
import * as ERROR from "../../../../constants/error";
import routes from '../../../common/routes';
import { NotFoundError } from '../../../common/components/ErrorBoundary';
import TaboolaWidget from '../../../common/components/TaboolaWidget';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import GetPageSchemaObjects from '../../../common/utils/getPageSchemaObjects';

const PERPAGE = 12;
const DEFAULT_PRIFILE_IMAGE_PATH = '/images/svg/profile_images/{{companyCode}}_Profile.svg';
const NTV_COMMENTARY_BADGE_NAME = '日本テレビ解説委員';
const NTV_COMMENTARY_LIST_PAGE_NAME = '日本テレビ解説委員の一覧へ';
class AuthorContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    isSpaError: PropTypes.func,
  };

  static getPaths = function (models, options, props) {
    let paths = [];
    const companyCode = _.get(props, 'routeHandler.params.companyCode', 'ntv');
    const authorId = _.get(options, 'author_id', '');

    const indexes = function (models, options, props) {
      const page = _.get(props, 'routeHandler.query.p');
      let from, to;
      if (page) {
        from = (page * PERPAGE) - PERPAGE;
        to = (page * PERPAGE) - 1;
      }
      return {
        from: from || (props && props.fromNum) || 0,
        to: to ? to
          : props && props.toNum
            ? props.toNum
            : options && options.numTitles
              ? options.numTitles + (props.fromNum || 0)
              : PERPAGE - 1,
      };
    };

    paths = paths.concat([
      ['author', companyCode, props.authorName],
      ['article', 'byAuthor', companyCode, authorId, indexes(models, options, props), [
        'id',
        'title',
        'thumbnail',
        'publish_start_date',
        'first_publish_date',
        'created_at'
      ]],
      ['article', 'byAuthor', companyCode, authorId, 'count'],
      ['article', 'byAuthor', companyCode, authorId, 'current_page'],
    ]);

    return paths;
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  static afterPrefetch = function (models, options, props) {
    return (prefetchResult) => {
      const companyCode = _.get(props, 'routeHandler.params.companyCode', 'ntv');
      const rootPath = ['author', companyCode, props.authorName];
      const item = _.get(prefetchResult, ['json'].concat(rootPath));
      if (!item) {
        return { error: ERROR.NOTFOUND };
      }
      return null;
    };
  };

  constructor(props, context) {
    super(props, context);
    const companyCode = _.get(context, 'routeHandler.params.companyCode', 'ntv');
    const isEachCompany = _.get(context, 'routeHandler.isEachCompany', false);
    const authorName = _.get(context, 'routeHandler.params.authorName', '');

    this.onClickPager = this.onClickPager.bind(this);

    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.lastPage = this.totalCount && Math.ceil(this.totalCount / PERPAGE);
    this.detail = this.model.getSync(['author', companyCode, authorName]); // オーサー詳細

    // 著者情報を取得してからIDを用いて紐づく記事を取得
    const authorId = _.get(this.detail, 'author_id', '');
    const rootPath = ['article', 'byAuthor', companyCode, authorId];
    this.items = this.model.getSync(rootPath); // オーサーに紐づく記事一覧
    this.totalCount = this.model.getSync(rootPath.concat('count'));
    if (this.items && Object.keys(this.items) && Object.keys(this.items).length) {
      const firstIndex = Object.keys(this.items)[0];
      this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
    }
    this.commentaryListLinkButton = null; // 解説委員ページのリンク
    this.to = isEachCompany ? routes.authorEachCompany : routes.author;
    this.params = isEachCompany ?
      {
        authorName: props.authorName,
        companyCode: companyCode
      } : {
        authorName: props.authorName
      };

    this.state = {
      dispose: null,
      fetchDataError: null,
      loading: false,
      newsCommentaryFlag: false, // 解説委員に属している人物かのフラグ
      sameAs: null,
      loading: true,
      profilePageObject: {}
    };

    this.generateContents = this.generateContents.bind(this);
    this.getArticleCards = this.getArticleCards.bind(this);
    this.convertDescription = this.convertDescription.bind(this);
    this.getSameAsDataAuthor = this.getSameAsDataAuthor.bind(this);
    this.setTargetBlankAnchor = this.setTargetBlankAnchor.bind(this);
    this.setProfilePageObjectSchema = this.setProfilePageObjectSchema.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchData();
    this.setProfilePageObjectSchema();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(_.get(prevState, 'profilePageObject'), _.get(this.state, 'profilePageObject'))) {
      this.setProfilePageObjectSchema();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.currentPage != _.get(nextProps, 'routeHandler.query.p')) {
      this.fetchData(nextProps, this.context, { scrollToTop: true });
    }
  }

  onClickPager(page) {
    if (page) {
      const props = Object.assign({}, this.props, {
        fromNum: page * PERPAGE - PERPAGE,
        toNum: page * PERPAGE - 1,
      });
      this.fetchData(props, this.context, { scrollToTop: true });
    }
  }

  fetchData(props = this.props, context = this.context, options = {}) {
    const companyCode = _.get(this.context, 'routeHandler.params.companyCode', 'ntv');
    const authorId = _.get(this.detail, 'author_id', '');
    const paths = this.constructor.getPaths(context.models, { author_id: authorId }, props);
    const evaluator = this.model.fetch(paths);
    evaluator
      .then(res => {
        this.items = _.get(res, ['json', 'article', 'byAuthor', companyCode, authorId], {});
        if (this.items) {
          const firstIndex = Object.keys(this.items)[0];
          this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
          this.totalCount = _.get(res, ['json', 'article', 'byAuthor', companyCode, authorId, 'count']);
          this.lastPage = this.totalCount && Math.ceil(this.totalCount / PERPAGE);
        }

        const newState = {
          fetchDataError: null,
          dispose: null,
          loading: false,
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);

        if (_.get(options, 'scrollToTop')) {
          window.scrollTo({ top: 0 });
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
          loading: false,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  /**
   * 著者に紐づく記事一覧生成
   */
  getArticleCards() {
    const authorDetail = this.detail;
    let items = this.items;
    if (items && _.size(items)) {
      items = _.slice(_.values(items), 0, PERPAGE);
    }

    const cards = _.compact(
      _.map(items || [], (itemData, index) => {
        if (!itemData || !itemData.source_systems) return;
        return (
          <TitleCard
            key={`tag_detail_list_card_${index}_${itemData.source_systems.id}`}
            itemData={itemData}
            default={true}
          />
        );
      }),
    );

    /**
     * 記事一覧のタイトルをオーサーの種別によって変える
     */
    const getArticleListTitle = (author) => {
      const authorName = _.get(author, 'name', '');
      if (!authorName) {
        return 'この著者の記事';
      }
      return _.get(author, 'type', '') === 'person' ? `「${authorName}」による記事` : `「${authorName}」の記事`;
    } 

    if ((!cards || _.isEmpty(cards)) && !_.get(this.state, 'loading')) {
      return (
        <div className="empty-contents">該当コンテンツが存在しません。</div>
      );
    } else {
      return (
        <React.Fragment>
          <h2 className="contents-title author-name-title">{getArticleListTitle(authorDetail)}</h2>
          <div className="news-list-items grid">
            {cards}
          </div>
        </React.Fragment>
      );
    }
  }

  /**
   * 著者の説明文を整形します。
   * aタグがある場合はリンクタグとして表示
   * 特定のSNS等のaタグはアイコンとして末尾に表示させます。
   */
  convertDescription(_description = '') {
    /**
     * 指定した順番でリンクアイコンを整列させて出力する
     * sotialLinkIconLineupで指定された順番（左から）で並ぶようにします
     * リンクDOMのIDに指定された各サービスの名前で順番に引っ掛けて配列を生成し直します
     */
    const getSotialLinkIconsLineup = (links) => {
      const sotialLinkIconLineup = ['x', 'youtube', 'tiktok', 'line', 'facebook', 'instagram']; // アイコンの整列順管理
      const newSotialLinkArray = _.map(sotialLinkIconLineup, (target) => {
        return _.find(links, (link) => _.get(link, 'props.id') === target);
      });
      return newSotialLinkArray;
    };

    if (!_description || _.isEmpty(_description)) {
      return '';
    }
    const sotialLinksArray = []; // 末尾に表示するリンクアイコンにするものたち
    let disablenewline = false; // アイコンにするURL直後の改行文字は無効化する
    const xRegex = /href=("|')(https:\/\/(x|twitter).com).+?("|')/;
    const youtubeRegex = /href=("|')(https:\/\/(www.youtube.com|youtu.be)).+?("|')/;
    const tiktokRegex = /href=("|')https:\/\/(www.tiktok.com).+?("|')/;
    const lineRegex = /href=("|')https:\/\/(liff.|page.)*\line\.me.+?("|')/;
    const facebookRegex = /href=("|')https:\/\/www\.facebook\.com.+?("|')/;
    const instagramRegex = /href=("|')https:\/\/www\.instagram\.com.+?("|')/;
    const tagValueRegex = /(?<=>)(.+?|^[\r\n|\n|\r])(?=<)/; // HTMLタグのValue(タグ内の値)

    const convertedDescription = _.compact(_.map(_.split(_description, newlineRegex), (line, index) => {
      if (line.match(newlineRegex) && !disablenewline) {
        // 改行文字にマッチしたらbrタグに置き換える
        return React.createElement('br', { key: index });
      } else if (line.match(newlineRegex) && disablenewline) {
        return '';
      }

      // aタグ、bタグをタグとして認識させる
      disablenewline = false;
      if (typeof line === 'string' && line.match(urlRegex)) {
        if (
          line.match(xRegex) ||
          line.match(youtubeRegex) ||
          line.match(tiktokRegex) ||
          line.match(lineRegex) ||
          line.match(facebookRegex) ||
          line.match(instagramRegex)
        ) {
          line = _.replace(line, tagValueRegex, ''); // タグのValieを消し去る
          if (line.match(xRegex)) {
            const dom = (
              <div id="x" className="author-sotial-icon" key={`author-sotial-icon-x-${index}`}>
                <span className="author-sotial-icon-link" dangerouslySetInnerHTML={{ __html: line }} />
                <img src="/images/x.png" alt="twitter" width="30" height="30" />
              </div>
            );
            sotialLinksArray.push(dom);
          } else if (line.match(youtubeRegex)) {
            const dom = (
              <div id="youtube" className="author-sotial-icon" key={`author-sotial-icon-youtube-${index}`}>
                <span className="author-sotial-icon-link" dangerouslySetInnerHTML={{ __html: line }} />
                <img src="/images/svg/youtube.svg" alt="youtube" width="30" height="30" />
              </div>
            );
            sotialLinksArray.push(dom);
          } else if (line.match(tiktokRegex)) {
            const dom = (
              <div id="tiktok" className="author-sotial-icon" key={`author-sotial-icon-tiktok-${index}`}>
                <span className="author-sotial-icon-link" dangerouslySetInnerHTML={{ __html: line }} />
                <img src="/images/svg/tiktok.svg" alt="tiktok" width="30" height="30" />
              </div>
            );
            sotialLinksArray.push(dom);
          } else if (line.match(lineRegex)) {
            const dom = (
              <div id="line" className="author-sotial-icon" key={`author-sotial-icon-line-${index}`}>
                <span className="author-sotial-icon-link" dangerouslySetInnerHTML={{ __html: line }} />
                <img src="/images/svg/line.svg" alt="line" width="30" height="30" />
              </div>
            );
            sotialLinksArray.push(dom);
          } else if (line.match(facebookRegex)) {
            const dom = (
              <div id="facebook" className="author-sotial-icon" key={`author-sotial-icon-facebook-${index}`}>
                <span className="author-sotial-icon-link" dangerouslySetInnerHTML={{ __html: line }} />
                <img src="/images/svg/facebook.svg" alt="facebook" width="30" height="30" />
              </div>
            );
            sotialLinksArray.push(dom);
          } else if (line.match(instagramRegex)) {
            const dom = (
              <div id="instagram" className="author-sotial-icon" key={`author-sotial-icon-instagram-${index}`}>
                <span className="author-sotial-icon-link" dangerouslySetInnerHTML={{ __html: line }} />
                <img src="/images/svg/instagram.svg" alt="instagram" width="30" height="30" />
              </div>
            );
            sotialLinksArray.push(dom);
          }
          disablenewline = true;
          return null;
        }

        // 解説委員かどうか
        if (this._isMounted && line.match(urlRegex)) {
          const newsCommentaryEl = document.getElementById('news-commentater-link');
          if (newsCommentaryEl && !_.get(this.state, 'newsCommentaryFlag')) {
            this.setState({
              newsCommentaryFlag: true,
              newsCommentaryHref: newsCommentaryEl.getAttribute('href')
            });
          }

          if (_.includes(line, 'news-commentater-link')) {
            return null;
          }
        }

        // アイコンに変換しないaタグは何もしない
        return <span className="author-normal-link" key={`str2a-anchor-${index}`} dangerouslySetInnerHTML={{ __html: line }} />
      } else if (typeof line === 'string' && line.match(boldRegex)) {
        // 太字
        return <span key={`str2a-bold-${index}`} dangerouslySetInnerHTML={{ __html: line }} />
      } else {
        return line;
      }
    }));

    return (
      <div className="author-description-cover">
        <div>
          {convertedDescription}
        </div>
        {_.get(this.state, 'newsCommentaryFlag') && (
          <div className="author-commentary-list-button">
            <a href={_.get(this.state, 'newsCommentaryHref', '')} target="_blank" rel="noopener noreferrer">{NTV_COMMENTARY_LIST_PAGE_NAME}</a>
          </div>
        )}
        <div className="author-sotial-links-icons-container">
          {getSotialLinkIconsLineup(sotialLinksArray)}
        </div>
      </div>
    );
  }

  /**
   * 著者詳細と著者に紐づく記事一覧等のコンテンツ全般を生成
   */
  generateContents(detail) {
    /**
     * 著者のプロフィール画像を取得
     * なければデフォルト画像へのパスを返す
     * オーサーの所属（設定元）に応じた各局NEWS NNNのロゴ画像（3段組みロゴ）を表示する
     */
    const getAuthorProfileImage = (_detail = {}) => {
      const profileImageUrl = _.get(_detail, 'profile_image_url');
      if (!profileImageUrl) {
        if (!_detail) return _.replace(DEFAULT_PRIFILE_IMAGE_PATH, '{{companyCode}}', 'NTV');
        const sourceAuthorProject = _.get(_detail, 'source_project_ref_id', 'n24');
        const broadCasterCode = sourceAuthorProject === 'n24' ? 'ntv' : _.replace(sourceAuthorProject, '-news', '');
        const broadCasterInfo = getBroadcasterData(broadCasterCode);
        const shortLabel = _.get(broadCasterInfo, 'short_label', '');
        return _.replace(DEFAULT_PRIFILE_IMAGE_PATH, '{{companyCode}}', shortLabel);
      } else {
        return profileImageUrl;
      }
    }

    const authorDetail = detail;
    const authorName = _.get(authorDetail, 'name', '');
    const authorDescription = _.get(authorDetail, 'description', '');
    const newsCommentaryFlag = _.get(this.state, 'newsCommentaryFlag', false);
    const authorProfileImageUrl = getAuthorProfileImage(authorDetail);
    const isSpMode = _.get(this.context, 'spMode', false);

    return (
      <div className="authorContent">
        {isSpMode ? (
          <div className="author-detail-container">
            <div className="author-detail-sp-header-container">
              <div className="author-image-container">
                <img src={authorProfileImageUrl} />
              </div>
                <div className="author-detail-sp-header-text">
                  {newsCommentaryFlag && (
                    <p className="author-news-commentary-badge">{NTV_COMMENTARY_BADGE_NAME}</p>
                  )}
                  <p className="contents-title author-title">{authorName}</p>
                </div>
                <Share title={authorName} />
            </div>
            <div className="author-detail-contents">
              <p className="player-text author-contents-text">
                {this.convertDescription(authorDescription)}
              </p>
            </div>
          </div>
        ) : (
            <div className="author-detail-container">
              <div className="author-image-container">
                <img src={authorProfileImageUrl} />
              </div>
              <div className="author-detail-contents">
                {newsCommentaryFlag && (
                  <p className="author-news-commentary-badge">{NTV_COMMENTARY_BADGE_NAME}</p>
                )}
                <div className='author-detail-header'>
                  <p className="contents-title author-title">{authorName}</p>
                  <Share title={authorName} />
                </div>
                <p className="player-text author-contents-text">
                  {this.convertDescription(authorDescription)}
                </p>
              </div>
            </div>
        )}
        <div className="author-articles-container">
          {this.getArticleCards()}
          <div key={`category_taboola_${Date.now()}`}>
            <TaboolaWidget target="below1" />
          </div>
          <div className="btn-block">
            {this.lastPage > 1 && (
              <PagerBlock
                currentPage={this.currentPage}
                lastPage={this.lastPage}
                keyPrefix={`tag_list_pager_`}
                to={this.to}
                params={this.params}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
  * ProfilePageのsameAsの値を取得する
  * 著者の説明文（※1）に、「公式サイト」の文言を含む<a>タグの指定がある場合 → 該当のタグのhrefの値をセット
  */
  getSameAsDataAuthor(item) {
    const description = _.get(item, 'description', '');
    if ((!item || !description) && _.get(this.state, 'sameAs') !== null) {
      return null;
    }

    let _description = _.split(description, newlineRegex);
    let href = null;
    _.forEach(_description, (line) => {
      if (this._isMounted && line.match(urlRegex)) {
        const el = document.getElementById('official-site-link');
        if (el) {
          href = el.getAttribute('href');
        }
      }
    });
    if (href && (_.get(this.state, 'sameAs') !== href)) {
      return href;
    }
    return null;
  }

  /**
   * 説明欄で使用されているaタグのリンクにtarget="_blank"を追加する
   * @param {string} parentClassName 設定したいアンカータグの親クラス名
   */
  setTargetBlankAnchor(parentClassName = '') {
    if (this._isMounted && parentClassName) {
      const links = document.getElementsByClassName(parentClassName);
      if (links && links.length > 0) {
        _.forEach(links, (link) => {
          const anchor = link.children[0];
          if (anchor) {
            anchor.setAttribute('target', '_blank');
            anchor.setAttribute('rel', 'noopener noreferrer');
          }
        });
      }
    }
  }

  /**
   * ProfilePage構造化データをセットする
   */
  setProfilePageObjectSchema() {
    const getPageSchemaObjects = new GetPageSchemaObjects({
      item: this.detail,
      canonical: null
    }, this.context);
    let profilePageObject = getPageSchemaObjects.generateProfilePageSchema();
    const sameAs = this.getSameAsDataAuthor(this.detail);
    if (sameAs) {
      profilePageObject = _.set(profilePageObject, 'sameAs', sameAs);
    } else {
      profilePageObject = _.omit(profilePageObject, 'sameAs');
    }
    this.setState({
      profilePageObject
    });
  }

  render() {
    const getCanonicalUrl = (_isEachCompany, _companyCode) => {
      if (!_isEachCompany) {
        return `/author/${_.get(this.detail, 'name')}${this.currentPage > 1 ? `?p=${this.currentPage}` : ''}`;
      } else {
        return `/n/${_companyCode}/author/${_.get(this.detail, 'name')}${this.currentPage > 1 ? `?p=${this.currentPage}` : ''}`;
      }
    }

    const getOgTitle = (_isEachCompany, _companyCode, _bloadcaster) => {
      if (!_isEachCompany) {
        return `「${_.get(this.detail, 'name')}」のニュース ${this.currentPage > 1 ? `${this.currentPage}ページ目` : ''}｜日テレNEWS NNN`;
      } else {
        return `「${_.get(this.detail, 'name')}」のニュース ${this.currentPage > 1 ? `${this.currentPage}ページ目` : ''}｜${_bloadcaster.label}のニュースサイト「${_bloadcaster.nnn}」。`
      }
    }

    if (!this.detail) {
      if (_.get(this.context, 'isSpaError')) {
        this.context.isSpaError();
      }
      throw new NotFoundError();
    }

    const isEachCompany = _.get(this.context, 'routeHandler.isEachCompany', false);
    const companyCode = _.get(this.context, 'routeHandler.params.companyCode', 'ntv');
    const bloadcaster = getBroadcasterData(companyCode)
    if (_.get(this.detail, 'content')) {
      _.set(this.detail, 'custom_data.content', this.detail.content);
    }
    const ogTitle = getOgTitle(isEachCompany, companyCode, bloadcaster);
    const canonical = getCanonicalUrl(isEachCompany, companyCode);

    // 構造化データ
    const ldJsonList = []
    if (!_.isEmpty(_.get(this.state, 'profilePageObject'))) {
      ldJsonList.push({
        type: 'application/ld+json',
        innerHTML: JSON.stringify(this.state.profilePageObject),
      });
    }

    // 説明欄のリンク（aタグ）にtarget="_blank"を設定
    this.setTargetBlankAnchor('author-sotial-icon-link');
    this.setTargetBlankAnchor('author-normal-link');
    this.setTargetBlankAnchor('author-commentary-list-button');


    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle(ogTitle) });
            metas.push({ property: 'og:type', content: 'article' });
            metas.push({ name: 'description', content: `「${_.get(this.detail, 'name')}」の著者が付いているニュース。日本テレビのニュースサイト「日テレNEWS NNN」は政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。` });
            metas.push({ property: 'og:description', content: `「${_.get(this.detail, 'name')}」の著者が付いているニュース。日本テレビのニュースサイト「日テレNEWS NNN」は政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。` });

            return (
              <Helmet
                title={ogTitle}
                meta={metas}
                script={ldJsonList}
              />
            );
          }}
        </HtmlContext.Consumer>
        {this.generateContents(this.detail)}
        <Analytics pageTitle={ogTitle} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(AuthorContent);
root.getPaths = AuthorContent.getPaths;
root.getPrefetchPaths = AuthorContent.getPrefetchPaths;
root.afterPrefetch = AuthorContent.afterPrefetch;
export default root;
