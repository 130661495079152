import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import url from 'url';
import Axios from '../../../../common/Axios';
import Player from './Player';
import nl2br from '../../../../common/react-nl2br';
import str2a from '../../../../common/react-str2a';
import removeTag from '../../../../common/react-removeTag';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import * as ERROR from '../../../../constants/error';
import { NotFoundError } from '../../../common/components/ErrorBoundary';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { MAX_SIZES_IMAGE, MAX_WIDTH_IMAGE } from "../../../../constants/config";
import BackgroundImage from '../../../common/components/BackgroundImage';
import DummyPlayButton from '../player/DummyPlayButton';

const YOUTUBE_REGEX = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*)(\?start=.+)?/;
const N24_LIVE_REF_ID = 'n24_live';

// リンク集: NTV => live_module , その他 => {nnn}_news_live 取得
const getUsePath = (props) => {
  const companyCode = _.get(props, 'companyCode', 'ntv');
  return companyCode === 'ntv' ? ['link', 'live_module'] : ['catalog', `${companyCode}_news_live`];
};

// GALLERYリンク集 「Youtube URL」 (link_url) から、Youtube Video IDを抽出
const getYoutubeVideoId = (linkItem) => {
  if (_.get(linkItem, 'status') !== 1) return;

  const url = _.get(linkItem, 'link_url', null);
  if (_.isEmpty(url)) return;

  return _.get(url.match(YOUTUBE_REGEX), '2');
}

/**
 * ライブページ
 */
class LiveContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    getModelData: PropTypes.func,
    topText: PropTypes.string,
    live: PropTypes.object,
  };

  static getPaths = function (models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    const usePath = getUsePath(props);
    return [].concat([
      rootPath,
      ['static', 'pages', 'about'],
      usePath,
    ]);
  };

  static getRootPath = function (models, options, props) {
    const companyCode = _.get(props, 'companyCode', 'ntv');
    return ['link', companyCode, 'nnn_live_module'];
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  // after prefetch event
  static afterPrefetch = function (models, options, props) {
    const isEachCompany = _.get(props, 'routeHandler.isEachCompany');
    const companyCode = _.get(props, 'companyCode', 'ntv'); // 本番用
    const rootPath = this.getRootPath(models, null, props);

    return (prefetchResult) => {
      // ntv(TRENTA UI)ライブページ 「/live」の場合
      if (!isEachCompany || companyCode === 'ntv') return null;

      // With Youtube Video ID
      const linkItems = _.get(prefetchResult, ['json'].concat(rootPath));
      const youtubeVideoId = getYoutubeVideoId(_.get(linkItems, '2'))
      if (youtubeVideoId) return null;

      // With Streaks Live ID
      const usePath = getUsePath(props);
      const catalogItem = _.get(prefetchResult, ['json'].concat(usePath));
      const refId = _.get(catalogItem, 'content_id');
      if (refId) return null;

      // Not Found LIVE (Youtube / Streaks) Player
      return { error: ERROR.NOTFOUND };
    };
  };

  constructor(props, context) {
    super(props, context);

    const rootPath = this.constructor.getRootPath(context.models, null, props);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    const items = this.model.getSync(rootPath);
    this.thumbnailItem = _.get(items, '0', {});
    this.logoItem = _.get(items, '1');
    this.liveItem = _.get(items, '2');

    const usePath = getUsePath(props);
    this.catalogItem = this.model.getSync(usePath); // 本番用

    if (!_.get(this.thumbnailItem, 'image_url') && _.get(props, 'routeHandler.isEachCompany')) {
      this.thumbnailItem.image_url = `/images/thumbnails/${companyCode}-thumbnail.png`;
    }
    this.state = {
      isLoaded: false,
      isLiveMedia: true,
      viewPlayerBtn: true,
    };

    this.axios = new Axios({ xhr: true });
    this.fetchLiveData = this.fetchLiveData.bind(this);
    this.buildYoutubeEmbed = this.buildYoutubeEmbed.bind(this);
    this.generateVideoPlayer = this.generateVideoPlayer.bind(this);
    this.handlePlayButton = this.handlePlayButton.bind(this);
    this.handleLiveError = this.handleLiveError.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async fetchLiveData() {
    let data;
    const isN24Live = _.get(this.props, 'companyCode', 'ntv') === 'ntv';
    try {
      const projectRefId = isN24Live ? 'n24' : _.get(this.props, 'companyCode', 'ntv') + '-news';
      const hostname = this.context.getModelData('services', 'playback', 'hostname');
      const playback = {
        name: 'papi',
        protocol: 'https',
        hostname: hostname,
        path: ['v1', 'projects', projectRefId, 'medias'],
      };
      if (_.isEmpty(playback)) {
        throw new Error('undefined playback config key');
      }
      /**
       * N24 Live: STREAKSメディア「ref:n24_live」参照 （「オリジン制御」設定によりローカルで閲覧できない場合あり）
       * 各局 Live: STREAKSライブチャンネルのメディアのrefIdにも同じ値を設定すること
       */
      const refId = isN24Live ? N24_LIVE_REF_ID : _.get(this.catalogItem, 'content_id');
      playback.pathname = _.join(_.concat(playback.path, `ref:${refId}`), '/');
      const _url = url.format(playback);
      await this.axios.get(_url, {}, {}).then(result => {
        data = _.get(result, 'data', {});
        if (data) {
          this.setState({
            viewPlayerBtn: false,
            isLiveMedia: true,
          });
        }
      }).finally(()=>{
        this.setState({
          isLoaded: true,
        });
      });
    } catch (e) {}
    return data;
  }

  buildYoutubeEmbed = (match) => {
    const id = _.get(match, '2');
    return (
      <div className="youtube-player">
        <LiteYouTubeEmbed id={id} />
      </div>
    );
  };

  /**
   * 動画の再生ボタンが押された時の処理
   */
  handlePlayButton() {
    if (!this.state.isLoaded) {
      this.setState({
        isLoaded: true,
        viewPlayerBtn: false,
      });
    }
  }

  // トークン取得時のエラー表示
  handleLiveError(liveErrorMessage) {
    this.setState({
      isLiveError: true,
      liveErrorMessage
    })
  }

  /**
   * 動画プレイヤーの生成
   * STREAKSの場合エンドユーザーが再生ボタンをクリックするまではプレイヤーを生成しません。
   */
  generateVideoPlayer() {
    const now = dayjs().format('YYYYMMDDHmm00');
    const livePosterSrc = _.get(this.thumbnailItem, 'poster_url') ? `${_.get(this.thumbnailItem, 'poster_url')}?date=${now}` : '';
    const isEachCompany = _.get(this.props, 'routeHandler.isEachCompany');
    const youTubeEmbed = (!_.isEmpty(url) && _.get(this.liveItem, 'status') === 1) ? this.buildYoutubeEmbed(url.match(YOUTUBE_REGEX)) : null;

    if (_.get(this.state, 'isLoaded')) {
      // 動画がある場合かつ再生ボタンが押された後
      if (!isEachCompany && youTubeEmbed) {
        return youTubeEmbed;
      } else if (!_.get(this.state, 'isLiveError', false)) {
        return (
          <div className="player-block">
            <Player
              type={'live'}
              catalogItem={this.catalogItem}
              isEachCompany={isEachCompany}
              fetchData={this.fetchLiveData}
              livePosterSrc={livePosterSrc}
              handleLiveError={this.handleLiveError}
            />
          </div>
        );
      } else {
        // ライブエラー表示
        return (
          <div className={classnames('player-block')} style={{ position: 'relative' }}>
            <div className='artwork articles-thumbnail error'>
              <div className="error-message">
                <p>{_.get(this.state, 'liveErrorMessage', '')}</p>
              </div>
            </div>
          </div>
        );
      }
    } else {
      // サムネイルがあってまだ再生されていないorサムネイルのみ
      return (
        <div
          className={classnames('player-block', { 'handle-play-button': this.state.viewPlayerBtn })}
          onClick={this.handlePlayButton}
          style={{ position: 'relative' }}
        >
          <BackgroundImage
            backgroundImageSize={MAX_SIZES_IMAGE}
            disableLazyLoad={true}
            className="artwork articles-thumbnail"
            url={livePosterSrc}
          />
          <DummyPlayButton viewPlayerBtn={_.get(this.state, 'viewPlayerBtn')} />
        </div>
      );
    }
  }

  render() {
    // live media データ読込後, liveデータ存在しない場合
    if (this.state.isLoaded && !this.state.isLiveMedia) throw new NotFoundError();

    const companyCode = _.get(this.props, 'routeHandler.params.companyCode');
    const isEachCompany = _.get(this.props, 'routeHandler.isEachCompany');
    const url = _.get(this, 'liveItem.link_url', null);
    const youTubeEmbed = (!_.isEmpty(url) && _.get(this, 'liveItem.status') === 1) ? this.buildYoutubeEmbed(url.match(YOUTUBE_REGEX)) : null;
    let logoUrl;
    if (isEachCompany && companyCode) {
      logoUrl = _.get(this.logoItem, 'image_url', _.get(getBroadcasterData(companyCode), 'img_src.l'));
    } else {
      logoUrl = "/images/live-logo.svg"
    }
    let text = '※『日テレNEWS24 ライブ配信』の推奨環境';
    const titleText = _.get(this, 'thumbnailItem.title', '設定したテキストが入ります')

    const canonical = `/live`;
    const topText = _.get(this.context, 'topText');

    const company = _.get(this.props.routeHandler, 'params.companyCode', 'n24');
    const bloadcaster = getBroadcasterData(company)
    const titleTag = company === 'n24'
      ? `24時間ライブ配信中｜日テレNEWS24｜${topText}`
      : `ライブ｜${bloadcaster.label}のニュース・${bloadcaster.area}のニュース｜${bloadcaster.nnn}`
    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];
            metas.push({ property: 'og:title', content: shortTitle(titleTag) });
            metas.push({ property: 'og:type', content: 'article' });

            if (_.get(this.thumbnailItem, 'content')) {
              const content = _.get(this.context, 'live.title') && !isEachCompany
                ? `『${(_.get(this.context, 'live.title'))}』${removeTag(_.get(this.thumbnailItem, 'content'))}`
                : removeTag(_.get(this.thumbnailItem, 'content'));
              metas.push({ name: 'description', content });
              metas.push({ property: 'og:description', content });
            }

            const linkTags = this.props.getPreloadImages();

            return <Helmet title={titleTag} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        <div className="liveContent">
          <div className="liveContent-header">
            <div className="liveContent-header-logo">
              <img src={logoUrl} alt="logo" />
            </div>
            <div className="liveContent-header-text">{titleText}</div>
          </div>
          <div className="liveContent-body">
            {this.generateVideoPlayer()}
            {_.get(this.context, 'live.title') && !isEachCompany
              ? <div className="liveContent-content">{`『${(_.get(this.context, 'live.title'))}』`}{str2a(nl2br(_.get(this.thumbnailItem, 'content', '')))}</div>
              : <div className="liveContent-content">{str2a(nl2br(_.get(this.thumbnailItem, 'content', '')))}</div>
            }
            {!_.get(this.props, 'routeHandler.isEachCompany') &&
              <div className="liveContent-subtext">
                <a href="/pages/kiyaku" rel="noopener noreferrer" target="_blank">
                  {text}
                </a>
              </div>
            }
          </div>
        </div>
        <Analytics pageTitle={'ライブトップ'} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(LiveContent);
root.getPaths = LiveContent.getPaths;
root.getRootPath = LiveContent.getRootPath;
root.getPrefetchPaths = LiveContent.getPrefetchPaths;
root.afterPrefetch = LiveContent.afterPrefetch;

export default root;
