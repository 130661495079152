'use strict';

import React from 'react';

export const urlRegex = /<a(?: .+?)?>.*?<\/a>/g;
export const boldRegex = /<b(?: .+?)?>.*?<\/b>/g;

/**
 * テキスト内にaタグbタグがある場合にHTMLタグとして認識させる
 */
export default function str2a(str) {
  if (!str) {
    return null;
  }
  if (!str.length) {
    return null;
  }
  if (!str.map) {
    return null;
  }

  const result = str.map((line, index) => {
    if (typeof line === 'string' && line.match(urlRegex)) {
      return <span key={`str2a-anchor-${index}`} dangerouslySetInnerHTML={{ __html: line }} />
    } else if (typeof line === 'string' && line.match(boldRegex)) {
      return <span key={`str2a-bold-${index}`} dangerouslySetInnerHTML={{ __html: line }} />
    } else {
      return <span key={`str2a-${index}`}>{line}</span>;
    }
  });

  return result;
};
