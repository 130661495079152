import React from "react";
import dayjs from 'dayjs';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _replace from 'lodash/replace';
import _indexOf from 'lodash/indexOf';
import lineClamp from '../../../../common/lineClamp';
import getDate from '../../../../common/getDate';
import routes from "../../../common/routes";
import MainViewLink from '../../../common/components/MainViewLink';
import getDisplayName from '../../../common/utils/getAuthorDisplayName';
import Share from './Share';

export default (props) => {
  const {
    item,
    categoryRouteParam,
    categoryCode,
    categoryName,
    browserInfo,
    companyLogoLink,
    bloadcaster,
    companyCode,
    isEachCompany
  } = props;

  /**
   * 記事のタイトル取得
   */
  const getArticleTitle = (article) => {
    return browserInfo.isIE ? lineClamp(_get(article, 'title'), 56) : _get(article, 'title');
  }

  /**
   * 著者名のリンクを生成
   */
  const getAuthorPageLink = (author = {}) => {
    if (!author || _isEmpty(author)) {
      return '';
    }

    const authorName = _get(author, 'name', '著者名がありません');
    const displayAuthorName = getDisplayName(authorName); // Nameの@以降を削る
    const soutceProjectRefId = _get(author, 'source_project_ref_id', 'n24');
    if (soutceProjectRefId === 'n24') {
      const params = {
        authorName: authorName,
      }
      return (
        <MainViewLink to={routes.author} params={params}>{lineClamp(displayAuthorName, 20)}</MainViewLink>
      );
    } else {
      const code = _replace(soutceProjectRefId, '-news', '');
      const params = {
        companyCode: code,
        authorName: authorName,
      }
      return (
        <MainViewLink to={routes.authorEachCompany} params={params}>{lineClamp(displayAuthorName, 20)}</MainViewLink>
      );
    }
  }

  /**
   * 著者タイプごとの要素生成
   */
  // 番組Author
  const getProgramAuthor = (programAuthors) => {
    if (_isEmpty(programAuthors)) return null;
    return _compact(_map(programAuthors, (author) => {
      return <span className="author" key={`program_author_${author.author_id}`}>{getAuthorPageLink(author)}</span>;
    }))
  }

  // グループAuthor
  const getGroupAuthor = (groupAuthors) => {
    if (_isEmpty(groupAuthors)) return null;
    return _compact(_map(groupAuthors, (author) => {
      return <span className="author" key={`program_author_${author.author_id}`}>{getAuthorPageLink(author)}</span>;
    }))
  }

  // 人物Author
  const getPersonAuthor = (personAuthors) => {
    if (_isEmpty(personAuthors)) return null;
    return _compact(_map(personAuthors, (author) => {
      return <span className="author" key={`program_author_${author.author_id}`}>{getAuthorPageLink(author)}</span>;
    }))
  }

  /**
   * 著者要素生成
   */
  const getAuthor = (authorInfos) => {
    if (_isEmpty(authorInfos)) {
      return null;
    }

    return (
      <div className="articleDetail-content-header-author-container">
        {getProgramAuthor(authorInfos.program)}
        {getGroupAuthor(authorInfos.group)}
        {getPersonAuthor(authorInfos.person)}
      </div>
    );
  }

  /**
   * ヘッダーカテゴリーと著者表示
   */
  const getArticleCategoryBadgeAuthor = (_item, _categoryCode, _categoryName, routeParam) => {
    const getBadge = () => {
      if (_categoryCode === "sponsored") {
        return <div className='articleDetail-content-header-article-ad'>Sponsored</div>;
      } else if (!_isEmpty(routeParam)) {
        return (
          <MainViewLink to={routeParam.to} params={routeParam.params}>
            <div className="articleDetail-content-header-categories-badge">
              {_categoryName}
            </div>
          </MainViewLink>
        );
      }
      return null;
    }

    return (
      <div className="articleDetail-content-header-categories-authors">
        {getBadge()}
        {getAuthor(_get(_item, 'authorInfos', {}))}
      </div>
    )
  }

  /**
   * 記事ヘッダーメタデータ
   */
  const getArticleHeaderMetadata = (_item, _categoryCode, _companyLogoLink, _bloadcaster) => {
    let categoryElement = null;
    const date = getDate(_item);

    if (_categoryCode === 'sponsored') {
      categoryElement = <div className='articleDetail-sponsor'>提供：{_get(_item, 'custom_data.sponsor')}</div>;
    } else if (!isEachCompany && _categoryCode && _categoryCode !== 'sponsored') {
      // 各局UIでは局ロゴを表示しない
      categoryElement = (
        <MainViewLink to={_companyLogoLink.path} params={_companyLogoLink.params}>
          <div className="articleDetail-content-header-categories-meta-bottom-logo">
            <img src={_get(_bloadcaster, 'img_src.l', '')} className="card-logo" alt={_get(_bloadcaster, 'label', '')} />
          </div>
        </MainViewLink>
      );
    }

    return (
      <React.Fragment>
        {categoryElement}
        <Share
          id={_get(_item, 'id', '')}
          title={_get(_item, 'title', '')}
        />
        <div className="articleDetail-content-header-categories-meta-bottom-date">
          {dayjs(date).format('YYYY年M月D日 H:mm')}
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="player-title articleDetail-content-header">
      {getArticleCategoryBadgeAuthor(item, categoryCode, categoryName, categoryRouteParam)}
      <h1 className="articleDetail-content-header-title">
        {getArticleTitle(item)}
      </h1>
      <div className={!companyCode ? "articleDetail-content-header-categories-meta-bottom" : "articleDetail-content-header-categories-meta-bottom-eachCompany"}>
        {getArticleHeaderMetadata(item, categoryCode, companyLogoLink, bloadcaster)}
      </div>
    </div>
  );
}
